<template>
  <div class="user-agreement">
    <div class="content">
      <h1>平台用户服务协议</h1>
      <div>
        <h2>特别提示</h2>
        <div>
          超算云桌面平台（以下简称“本平台”）非常重视用户的服务体验，本文适用于用户使用任何本平台提供的服务，在此特别提醒您（用户）在注册成为本平台用户或登录本平台之前，请认真阅读本平台用户服务协议（以下简称“本协议”）， 确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录以及使用本平台所涉任何服务。 您的注册、登录、使用等行为将被视为对本协议的接受，并同意接受本协议各项条款的约束。
        </div>
        <div>
          本协议约定山东正云信息科技有限公司（以下简称“本公司”）与用户之间关于本平台软件服务（以下简称“服务”）的权利义务。 “用户”是指注册、登录、使用本平台服务的个人或相关使用者。本协议可由本平台软件系统运行后台及本公司随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知。用户可在本平台中查阅最新版协议条款，也请用户随时关注本平台用户协议的更新情况，以免造成不必要的误解和纠纷。 在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用本平台提供的服务；若您继续使用本平台提供的任何服务，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用平台服务。
        </div>
        <h3>一、用户账号注册基本要求</h3>
        用户在使用本平台服务前需要注册个人账号。该账号应当使用手机号码绑定注册，请用户使用尚未与本账号绑定的手机号码，以及未被本公司根据本协议封禁的手机号码注册账号。 本公司可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
        <h3>二、用户个人隐私信息保护</h3>
        1、我们可能收集的信息<br>
        我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。<br>
        （1）您提供的信息<br>
        a. 您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、身份证明、电子邮件等；<br>
        b. 如您使用我们的服务需与用户的银行账户或其他支付工具的账户关联方能实现时，您向我们提供您的银行账户信息或其他支付工具的账户信息；<br>
        （2）我们获取的您的信息<br>
        您使用服务时我们可能收集如下信息：<br>
        a.日志信息，指您使用我们的服务时，系统自动采集的技术信息，包括：<br>
        1）  设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；<br>
        2、我们如何使用您的信息<br>
        （1）我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：<br>
        a. 向您提供服务。在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；<br>
        b. 帮助我们设计新服务，改善我们现有服务；<br>
        c. 评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；<br>
        d. 让您参与有关我们产品和服务的调查。<br>
        （2）为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。<br>
        3、如果本平台运行后台及本公司发现或收到他人举报或投诉用户违反本协议约定的，本公司有权在收到举报后24小时内不经通知随时对相关内容， 包括但不限于用户资料进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁 、功能封禁等处罚，且不必通知用户处理结果。<br>
        4、因违反用户协议被封禁的用户，可以自行与本平台后台或本公司公开的方式进行联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉， 本平台后台或本公司将对申诉进行审查，并自行合理判断决定是否变更处罚措施。<br>
        5、用户理解并同意，本平台后台及本公司有权依据合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动， 并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br>
        6、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当对本公司与合作公司、关联公司等相关方的赔偿，并使之免受损害。<br>
        <h3>三、用户使用内容规范</h3>
        1、本条所述用户使用内容是指用户使用本平台的过程中所制作、上传、复制、发布、传播的任何内容，包括但不限于账号头像、名称等注册信息及认证资料， 或文字（包括艺术字）、符号、标识、语音、图片、（自定义）照片、视频（包括用户自行制作或上传的短视频等）、图文、模型、形象及其相互组合的内容等上传、发送、回复或自动回复消息和相关链接页面， 以及其他使用本账号或本平台服务所产生的内容。<br>
        2、用户不得利用本账号或本平台服务制作、上传、复制、发布、传播如下法律、法规和政策禁止的内容：<br>
        (1) 违反宪法所确定的基本原则的；<br>
        (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
        (3) 损害国家荣誉和利益的；<br>
        (4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br>
        (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
        (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
        (7) 诱导未成年人违法犯罪和散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
        (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br>
        (9) 危害社会公德，损害民族优秀文化传统的；<br>
        (10)非法的广播电视频道、视听节目网站提供的非法视频内容；<br>
        (11)有关法律、行政法规和国家规定禁止的其他内容。<br>
        3、用户不得利用本账号或本平台服务制作、上传、复制、发布、传播如下干扰本平台正常运营，以及侵犯其他用户或第三方合法权益的内容：<br>
        (1) 含有任何性或性暗示的；<br>
        (2) 含有辱骂、恐吓、威胁内容的；<br>
        (3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br>
        (4) 涉及他人隐私、个人信息或资料的；<br>
        (5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br>
        (6) 含有其他干扰本平台服务正常运营和侵犯其他用户或第三方合法权益内容的信息。<br>
        4、用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。<br>
        5、用户须对自己在使用本平台服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在本公司首先承担了因其用户行为导致的行政处罚或侵权损害赔偿的法律责任后， 用户应给予本公司等额的赔偿，以及本公司因此而受到的其他损失等。<br>
        <h3>四、用户使用平台规则</h3>
        1、用户在使用本平台服务中或通过本平台所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表本公司的观点、立场或政策，本公司对此不承担任何责任。<br>
        2、用户不得利用本账号或本平台服务进行如下行为：<br>
        (1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br>
        (2) 强制、诱导其他用户关注、点击链接页面或分享信息的；<br>
        (3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br>
        (4) 利用技术手段批量建立虚假账号的；<br>
        (5) 利用本账号或本平台服务从事任何违法犯罪活动的；<br>
        (6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br>
        (7) 其他违反法律法规规定、侵犯其他用户合法权益、干扰本平台及其所属公司正常运营或本平台未明示授权的行为。<br>
        (8)如果用户长期没有登录或使用平台,本公司将视为用户已经放弃使用本平台，本公司有权删除掉用户的登录账号。<br>
        3、用户须对利用本账号或本平台服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与本平台及本公司无关。 如因此给本平台及本公司或任何第三方造成损害的，用户应当依法予以赔偿，本公司亦将保留追究相关用户及使用人的法律、经济等全部责任。<br>
        4、本公司提供的本平台服务中可能包括广告，用户同意在使用过程中显示本公司和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责， 对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，本公司不承担任何责任。<br>
        <h3>五、服务风险声明</h3>
        1、用户完全理解并同意，本平台服务涉及到互联网等服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、GSM网络、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。 使用本服务的用户须承担以上风险，本公司对服务之及时性、安全性、准确性不作担保，对因此导致的任何问题不承担任何责任。本平台及本公司将不对数据安全和作业稳定性负责。<br>
        2、对于系统发生故障影响到本平台服务的正常运行，本公司承诺会及时处理并尽可能进行修复。但用户因此而产生的间接和精神等一切损失，本公司不承担任何责任。 此外，本平台保留不经事先通知为优化、升级或其他目的暂停本平台服务任何部分或全部的权利。<br>
        3、本平台及本公司郑重提请您注意，任何经由本平台服务上传的相关内容，均由账号所有者承担责任。本公司无法控制经由本平台服务上传的内容，也无法对用户的使用行为进行全面控制， 因此不保证内容的合法性、正确性、完整性、真实性或品质；您已预知使用本平台服务时，可能会遇到的各种问题，并同意将自行加以判断并承担所有风险，而不依赖于本平台及本公司。 但在任何情况下，本平台及本公司有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本平台服务的全部或部分功能，保存有关记录，并向有关机关报告。<br>
        <h3>六、其他提示</h3>
        1、本公司郑重提醒用户注意本协议中免除本平台及本公司责任和限制用户权利的条款，请用户仔细阅读，自主考虑及辨别相关风险。未成年人应在法定监护人的陪同下阅读本协议及使用本平台服务。<br>
        2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和本平台及本公司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交本公司住所地有管辖权的人民法院管辖。 本公司有权启动必要的刑事及民事法律程序，维护本公司的合法权益，追究违法用户的法律责任。<br>
        3、本协议的任何条款无论因何种原因无效或不具有可执行性，其余条款仍有效，对双方具有约束力。<br>
        4、本平台软件及本公司未行使或执行本服务协议任何权利或规定，不构成对前述权利或其他权利之放弃。<br>
        5、本协议未尽事宜，以本平台运行后台及本公司后续不定期更新或补充本协议相关内容为准，更新及补充条款与本协议不可分割。<br>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.user-agreement{
  height: 100vh;
  overflow-y: auto;
  padding: 50px 0;
  line-height: 24px;
  .content{
    max-width: 1000px;
    margin: 0 auto;
  }
  h1{
    text-align: center;
    font-weight: bold;
  }
  h2,h3{
    padding-top: 15px;
    font-weight: 600;
  }
}

</style>
